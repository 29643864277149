<template scoped>
  <div>
    <el-form-item
      :rules="rules.type"
      label="Type"
      prop="connect.type"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        @change="type_change"
        v-model="logic.connect.type"
        :style="{ width: '100%' }"
        placeholder="Select the Type"
      >
        <el-option
          v-for="item in supportType"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <component :is="content" :logic="logic"></component>
  </div>
</template>

<script>
export default {
  props: {
    logic: Object,
    data: Object,
  },
  computed: {},
  data: function () {
    return {
      content: null,
      supportType: [
        {
          name: "Bluetooth",
          value: "Bluetooth",
        },
      ],
      rules: {
        type: [{ validator: this.validator_type, trigger: "blur" }],
      },
    };
  },
  created: function () {
    if (this.logic.connect.type) {
      this.type_change(this.logic.connect.type);
    }
  },
  methods: {
    validator_type: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the type should not be empty"));
      } else {
        callback();
      }
    },
    type_change: function (val) {
      switch (val) {
        case "Bluetooth":
          this.content = () =>
            import("./Bluetooth/Panel");
          break;
        default:
          this.content = null;
          break;
      }
    },
  },
};
</script>